import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import {
  Heading,
  Subtitle,
  Title,
  Link,
  Icon,
} from 'components/common';
import { useProducts } from 'lib/product';
import { isEmpty } from 'lib/javascript';
import { routePaths } from 'appConfig';
import { safeContent } from 'utils';
import { m } from 'framer-motion';

import ProductCard from '../ProductCard';

const MAX_SHOWING_PRODUCTS = 4;

const ProductsSection = ({ subtitle, title, description, query }) => {
  const { products, error, isLoading } = useProducts({ query });

  const showingProducts = products.slice(0, MAX_SHOWING_PRODUCTS);

  // when has more products than able to show,
  // and is queried by category IDs only because currently the products page only support by ONE category
  const shouldShowViewMore =
    products.length > showingProducts.length &&
    !isEmpty(query.category_ids) &&
    query.category_ids.length === 1 &&
    isEmpty(query.collection_ids);

  const viewMoreLinkTo = `${routePaths.PRODUCTS}?category_ids=${query?.category_ids?.[0]}`;

  const renderProducts = () => {
    if (isLoading) return <p>Fetching products...</p>;

    if (error)
      return <p>Something went wrong when fetching products. </p>;

    if (isEmpty(products)) {
      return (
        <p className="tw-italic">Something YUMMY is coming soon!</p>
      );
    }

    return showingProducts.map((product, index) => (
      <m.div
        key={product.id}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            delay: 0.225 * (index + 1),
          },
          type: 'spring',
        }}
        viewport={{ once: true }}
      >
        <ProductCard product={product} className="text-center" />
      </m.div>
    ));
  };

  return (
    <React.Fragment>
      <m.div
        key={query}
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.1 }}
        viewport={{ once: true }}
      >
        <Heading className="mb-5" textAlign="center">
          {subtitle && (
            <Subtitle className="mb-1" style={{ color: '#a42e27' }}>
              {subtitle}
            </Subtitle>
          )}
          <Title className="tw-font-extrabold">{title}</Title>
          {description && (
            <p
              className="text-center"
              dangerouslySetInnerHTML={safeContent(description)}
            />
          )}
        </Heading>
      </m.div>

      <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-8">
        {renderProducts()}
      </div>

      {shouldShowViewMore && (
        <div className="more-container text-center mt-3 mb-3">
          <Link
            to={viewMoreLinkTo}
            className="btn btn-outline-dark-3 btn-more"
          >
            <span>VIEW MORE</span>{' '}
            <Icon name="long-arrow-right" fontSize="inherit" />{' '}
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};

ProductsSection.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  query: PropTypes.shape({
    category_ids: PropTypes.arrayOf(PropTypes.string),
    collection_ids: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ProductsSection;
