import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import homeData from 'lib/home/data/raw.json';

import HeaderSEO from 'components/HeaderSEO';
import {
  Carousel,
  Icon,
  IntroSlide,
  Banner,
  BannerContent,
  BannerTitle,
  BannerLink,
  Testimonial,
  Heading,
  Title,
  Container as TwContainer,
  TextBalancer,
} from 'components/common';
import { ProductsSection } from 'lib/sellingProduct';
import { OurMission, TopSection } from 'lib/home';
import { FeaturedBy } from 'lib/misc';
import { m } from 'framer-motion';
import useMediaQuery from 'hooks/useMediaQuery';

export default function Home() {
  const { intro_carousel, product_sections, testimonials } = homeData;
  const isMobile = useMediaQuery(
    'only screen and (max-width: 480px)',
  );
  const isTablet = useMediaQuery(
    'only screen and (min-width: 481px) and (max-width: 768px)',
  );

  return (
    <React.Fragment>
      <HeaderSEO
        title="Yummy Bros - The Most Enjoyable Healthy Meal Prep in Singapore! | From $8/meal"
        description="With Yummy Bros highly-rated meal prep, you'll have high protein, customizable, & healthy food delivered straight to your doorstep! Order online today!"
        path="https://www.yummybros.com"
        image="https://yb-production.s3.ap-southeast-1.amazonaws.com/images/static/facebook-share-image-2020.jpeg"
        ogType="website"
        includeBusinessMarkup
      />
      <Carousel
        adClass="intro-slider owl-theme owl-nav-inside owl-light"
        carouselOptions={{
          autoplay: false,
          autoplayTimeout: 5000,
          loop: true,
          nav: !isMobile && !isTablet,
          dots: false,
        }}
      >
        {intro_carousel.map(
          (
            {
              bg_image,
              font_theme,
              subtitle,
              title,
              description,
              primary_link_button,
              secondary_link_button,
              position,
            },
            index,
          ) => (
            <IntroSlide
              key={index}
              bgImage={bg_image}
              fontTheme={font_theme}
              subtitle={subtitle}
              title={title}
              description={description}
              primaryLinkButton={primary_link_button}
              secondaryLinkButton={secondary_link_button}
              position={position}
            />
          ),
        )}
      </Carousel>

      <TopSection />

      {product_sections.map((productSection) => (
        <Container className="recent-arrivals">
          <ProductsSection
            subtitle={productSection['subtitle']}
            title={productSection['title']}
            description={productSection['description']}
            query={productSection['query']}
          />

          <hr class="mb-6" />
        </Container>
      ))}

      <Container className="tw-mb-3">
        <Row>
          <Col md={6} className="mb-2 left-banner-container">
            <m.div
              initial={{ opacity: 0, x: -100 }}
              transition={{ type: 'spring', duration: 1, delay: 0.1 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="tw-w-full"
            >
              <Banner
                bgImageProps={{
                  src:
                    '/assets/images/landing/how-to-order-yb-bg-image.png',
                  alt:
                    'A Plate Of Food With Chopsticks And Sauce On A Red Background.',
                  childClassname: 'full-height',
                }}
                border="always"
                className="tw-w-full tw-h-full"
              >
                <BannerContent
                  style={{ height: '534px', paddingRight: '7rem' }}
                  className="tw-text-shadow-light"
                >
                  <BannerTitle className="text-white home-banner-title tw-font-extrabold tw-text-5xl">
                    How To Order Yummy Bros?
                  </BannerTitle>
                  <p className="text-white">
                    New to the Yummy Army? Worry not! We've prepared a
                    simple ordering guide to help get you onboard!
                  </p>
                  <BannerLink to="/pages/how-to-order">
                    TEACH ME!{' '}
                    <Icon
                      name="long-arrow-right"
                      fontSize="inherit"
                    />
                  </BannerLink>
                </BannerContent>
              </Banner>
            </m.div>
          </Col>
          <Col md={6}>
            <m.div
              initial={{ opacity: 0, x: 100 }}
              transition={{ type: 'spring', duration: 1, delay: 0.1 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="tw-w-full tw-mb-8"
            >
              <Banner
                bgImageProps={{
                  src:
                    '/assets/images/landing/weight-loss-guide-bg-image.png',
                  alt:
                    'A Meal In A White Dish With Rice, Carrots, And Broccoli.',
                }}
                border="always"
              >
                <BannerContent
                  style={{ paddingRight: '7rem' }}
                  className="tw-text-shadow-light"
                >
                  <BannerTitle className="text-white home-banner-title tw-font-extrabold tw-text-5xl">
                    Weight Loss Guide
                  </BannerTitle>
                  <p className="text-white">
                    Weight loss is a very simple process. We didn't
                    say it'll be easy; but with Yummy Bros, your
                    journey will be an enjoyable one!
                  </p>
                  <BannerLink to="/pages/weight-loss-tips">
                    TELL ME HOW!{' '}
                    <Icon
                      name="long-arrow-right"
                      fontSize="inherit"
                    />
                  </BannerLink>
                </BannerContent>
              </Banner>
            </m.div>
            <m.div
              initial={{ opacity: 0, x: 100 }}
              transition={{ type: 'spring', duration: 1, delay: 0.1 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="tw-w-full"
            >
              <Banner
                bgImageProps={{
                  src:
                    '/assets/images/landing/meal-care-bg-image.png',
                  alt: 'A Plate Of Food With Vegetables And Rice.',
                }}
                border="always"
              >
                <BannerContent
                  style={{ paddingRight: '7rem' }}
                  className="tw-text-shadow-light"
                >
                  <BannerTitle className="text-white home-banner-title tw-font-extrabold tw-text-5xl">
                    Meal Care Tips
                  </BannerTitle>
                  <p className="text-white">
                    Never meal prepped before? Worry not! We'll show
                    you how to store, thaw, & re-heat your meals!
                  </p>
                  <BannerLink to="/pages/mealcare">
                    SHOW ME!{' '}
                    <Icon
                      name="long-arrow-right"
                      fontSize="inherit"
                    />
                  </BannerLink>
                </BannerContent>
              </Banner>
            </m.div>
          </Col>
          <Col>
            <m.div
              initial={{ opacity: 0, y: 100 }}
              transition={{ type: 'spring', duration: 1, delay: 0.1 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="tw-w-full tw-mt-8"
            >
              <Banner
                bgImageProps={{
                  src:
                    '/assets/images/landing/yb-express-bg-image.jpg',
                  alt: 'Get YB Meal Preps in an instant.',
                }}
                border="always"
              >
                <BannerContent
                  style={{ paddingRight: '7rem' }}
                  className="tw-text-shadow-light tw-h-full sm:tw-h-[300px] md:tw-h-[350px]"
                >
                  <BannerTitle className="text-white home-banner-title tw-font-extrabold tw-text-5xl">
                    Eager to Stock Up on Yummy Bros?
                  </BannerTitle>
                  <TextBalancer className="text-white">
                    Can’t wait 1-2 days for us to deliver? Missed our
                    cut-off time to order? Check out YB Express! With
                    islandwide delivery within 2 hours, you’ll get YB
                    Meal Preps in an instant!
                  </TextBalancer>
                  <BannerLink to="/ybexpress" target="_blank">
                    TAKE ME THERE!{' '}
                    <Icon
                      name="long-arrow-right"
                      fontSize="inherit"
                    />
                  </BannerLink>
                </BannerContent>
              </Banner>
            </m.div>
          </Col>
        </Row>
        <hr />
      </Container>

      <div className="tw-mb-20"></div>

      <TwContainer className="tw-mb-20">
        <div className="tw-flex tw-justify-center">
          <div className="lg:tw-basis-10/12 tw-basis-full">
            <FeaturedBy />
          </div>
        </div>
        <hr />
      </TwContainer>

      <Container className="mb-5">
        <Heading contentAlign="center" className="mb-5">
          <Title className="tw-font-extrabold">
            Here's What The Yummy Army Is Saying!{' '}
          </Title>
        </Heading>

        <Carousel
          adClass="owl-theme owl-testimonials-photo"
          carouselOptions={{
            autoplay: true,
            autoplayTimeout: 5000,
            nav: false,
            dots: true,
            margin: 20,
            loop: true,
            responsive: {
              0: {
                items: 1,
              },
              768: {
                items: 2,
              },
              1200: {
                items: 2,
                nav: true,
              },
            },
          }}
        >
          {testimonials.map(
            ({ name, job, image, content }, index) => (
              <Testimonial
                key={index}
                name={name}
                job={job}
                image={image}
                content={content}
              />
            ),
          )}
        </Carousel>
      </Container>

      <OurMission />
    </React.Fragment>
  );
}
