import {
  Banner,
  BannerButton,
  BannerContent,
  BannerSubtitle,
  BannerTitle,
  Link,
} from 'components/common';
import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

export const OurMission = (): ReactNode => {
  return (
    <Banner
      bgImageProps={{
        src: '/assets/images/checked-background-black-faded.jpeg',
        alt: 'banner 1',
      }}
      size="big"
    >
      <BannerContent
        contentAlign="center"
        textAlign="center"
        style={{ maxWidth: '1000px', padding: '0 5rem' }}
      >
        <>
          <BannerSubtitle className="text-white">
            <>Our Mission</>
          </BannerSubtitle>
          <BannerTitle className="text-white tw-font-extrabold">
            <>
              MAKE DIETING AN
              <br /> <span>ENJOYABLE JOURNEY</span>
            </>
          </BannerTitle>
          <p className="text-white">
            A diet doesn't have to be strict, bland, or boring. It
            also doesn't have to be expensive or troublesome.
          </p>
          <p className="text-white">
            We believe that a healthy diet is one that can be adopted
            for the long-term and achieves results at the same time.
            While the diet should help obtain a desired calorie &
            protein intake goal, it should also taste great and be
            simple simple to execute - without the hassle of grocery
            shopping, cooking, washing, ingredient weighing, & recipe
            hunting.
          </p>
          <p className="text-white">
            With Yummy Bros highly-rated meal prep, you'll have high
            protein, customizable, & healthy food delivered straight
            to your doorstep. Order online today!
          </p>

          <Row style={{ justifyContent: ' center' }}>
            <Col xs={12} sm="auto" className="mb-2">
              <Link
                to="/pages/our-story"
                style={{ textDecoration: 'none' }}
              >
                <BannerButton>
                  <>Read More</>
                </BannerButton>
              </Link>
            </Col>
            <Col xs={12} sm="auto">
              <Link
                to="/pages/how-to-order"
                style={{ textDecoration: 'none' }}
              >
                <BannerButton>
                  <>How to Order</>
                </BannerButton>
              </Link>
            </Col>
          </Row>
        </>
      </BannerContent>
    </Banner>
  );
};
