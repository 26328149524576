import React from 'react';

import style from './style.module.scss';
import classNames from 'classnames';
import { Button, ButtonProps } from '../Button';

type Props = {
  [Property in keyof ButtonProps]?: ButtonProps[Property];
} & {
  className?: string;
};

const BannerButton = ({ className, ...props }: Props) => {
  return (
    <Button
      className={classNames(className, style['btn'])}
      {...props}
    />
  );
};

BannerButton.propTypes = {};

export default BannerButton;
