import React from 'react';
import PropTypes from 'prop-types';

import { safeContent } from 'utils';
import Link from '../link';

import { Button } from 'components/common/Button';
import { ImageBackground } from '../ImageBackground';
import useMediaQuery from 'hooks/useMediaQuery';
import classNames from 'classnames';

const FONT_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

const createThemedFontClassName = (className, fontTheme) =>
  `${className} ${fontTheme}`;

const IntroSlide = ({
  bgImage,
  subtitle,
  title,
  description,
  fontTheme,
  adClass,
  primaryLinkButton,
  secondaryLinkButton,
  position,
}) => {
  const isMobile = useMediaQuery(
    'only screen and (max-width: 480px)',
  );
  const isTablet = useMediaQuery(
    'only screen and (min-width: 481px) and (max-width: 768px)',
  );
  const isLaptop = useMediaQuery(
    'only screen and (min-width: 769px)',
  );
  const isMaxLaptop = useMediaQuery(
    'only screen and (max-width: 768px)',
  );
  const isLargerDesktop = useMediaQuery(
    'only screen and (min-width: 1280px)',
  );

  let src = bgImage.src;

  if (bgImage.mobile && (isMobile || isTablet)) {
    src = bgImage.mobile;
  }

  let objectPosition = 'center center';

  if (isMobile || isTablet) objectPosition = 'top';
  if (isLargerDesktop)
    objectPosition =
      position === 'left' ? 'center left' : 'center right';

  let introSlideClassNames = `tw-items-center ${
    position === 'left'
      ? 'tw-justify-start tw-pl-0'
      : 'tw-justify-end tw-pr-0'
  }`;

  if (isMobile || isTablet)
    introSlideClassNames =
      'tw-items-end tw-justify-center tw-pb-14 tw-px-4';
  if (isMobile)
    introSlideClassNames += ' tw-min-h-[550px] tw-h-[550px]';
  if (isTablet)
    introSlideClassNames += ' tw-min-h-[650px] tw-h-[650px]';

  const getQuery = (query) => {
    if (query) {
      return `?${Object.keys(query)[0]}=${Object.values(query)[0]}`;
    }
  };

  return (
    <div style={{ zIndex: 9 }}>
      <ImageBackground
        src={src}
        alt={bgImage.alt}
        priority
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#b6b6b5',
        }}
        objectPosition={objectPosition}
      >
        <div className={`intro-slide ${introSlideClassNames}`}>
          <div
            className={`intro-content ${
              isMaxLaptop
                ? 'text-center'
                : position === 'left'
                ? 'text-left'
                : 'text-right'
            } ${adClass}`}
          >
            <h3
              className={createThemedFontClassName(
                'intro-subtitle',
                fontTheme,
              )}
            >
              {subtitle}
            </h3>

            <h1
              className={classNames(
                createThemedFontClassName(
                  'intro-title tw-text-[5.6rem] sm:tw-text-8xl lg:tw-text-10xl tw-font-black',
                  fontTheme,
                ),
                position === 'left' && isLaptop
                  ? '-tw-ml-[4px]'
                  : '-tw-mr-[4px]',
              )}
              dangerouslySetInnerHTML={safeContent(title)}
            ></h1>

            {description ? (
              <h3
                className={createThemedFontClassName(
                  'intro-subtitle tw-mt-8 tw-mb-5 lg:tw-mb-0',
                  fontTheme,
                )}
              >
                {description}
              </h3>
            ) : (
              ''
            )}

            {primaryLinkButton && (
              <Link
                to={`${primaryLinkButton.to}${
                  getQuery(primaryLinkButton.query) ?? ''
                }`}
              >
                <Button color="primary">
                  {primaryLinkButton.label}
                </Button>
              </Link>
            )}
            {secondaryLinkButton && (
              <Link
                to={`${secondaryLinkButton.to}${
                  getQuery(secondaryLinkButton.query) ?? ''
                }`}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{ marginLeft: '12px' }}
                >
                  {secondaryLinkButton.label}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </ImageBackground>
    </div>
  );
};

IntroSlide.propTypes = {
  bgImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    mobile: PropTypes.string,
  }).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  adClass: PropTypes.string,
  fontTheme: PropTypes.oneOf(Object.values(FONT_THEME)),
  primaryLinkButton: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    query: PropTypes.string,
  }),
  secondaryLinkButton: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    query: PropTypes.string,
  }),
  position: PropTypes.oneOf(['left', 'right']),
};

IntroSlide.defaultProps = {
  fontTheme: FONT_THEME.DARK,
};

export default IntroSlide;
