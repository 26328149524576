import { useAuth } from 'lib/auth';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { UserSummaries } from './UserSummaries';
import { WhatIsYummyBros } from './WhatIsYummyBros';

export const TopSection = () => {
  const { session } = useAuth();

  const defaultTopSections = <WhatIsYummyBros />;
  const [topSections, setTopSections] = useState(defaultTopSections);

  useEffect(() => {
    const showChoosenTopSections = async () => {
      const shouldShowUserSummary = session !== null;
      if (shouldShowUserSummary) {
        setTopSections(<UserSummaries />);
        return;
      }

      setTopSections(defaultTopSections);
    };

    showChoosenTopSections();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <Container className="mt-5 justify-content-center">
      {topSections}

      <hr className="mb-6" />
    </Container>
  );
};
