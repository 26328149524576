import homeData from 'lib/home/data/raw.json';
import {
  EqualColsGrid,
  Icon,
  IconBox,
  IconIconSet,
} from 'components/common';
import { m } from 'framer-motion';

const { feature_boxes } = homeData;
const ICON_SIZE = 36;

const WhatIsYummyBros = () => {
  return (
    <EqualColsGrid cols={3}>
      {feature_boxes.map(({ icon_set, icon, title, text }, index) => {
        const iconSet = icon_set as IconIconSet;

        return (
          <m.div
            key={index}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.25 * (index + 1),
              type: 'spring',
              duration: 1,
            }}
          >
            <IconBox
              boxStyle="text-center"
              icon={
                <Icon
                  iconSet={iconSet}
                  name={icon}
                  size={ICON_SIZE}
                  style={{ color: '#000' }}
                />
              }
              title={title}
              text={text}
            />
          </m.div>
        );
      })}
    </EqualColsGrid>
  );
};

export { WhatIsYummyBros };
