import React, { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
const OwlCarousel = dynamic(import('react-owl-carousel'), {
  ssr: false,
});

const Carousel = ({ adClass, carouselOptions, children }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;

    const hasChildren = () =>
      children &&
      (children.length > 0 ||
        (children && children.length === undefined));

    const isQuickViewCarousel = () =>
      carousel?.props?.className?.indexOf('owl-quickview') > -1;

    if (hasChildren() && isQuickViewCarousel()) {
      let dots;

      if (document.querySelector('#owl-dots .carousel-dot')) {
        if (!document.querySelector('#owl-dots .carousel-dot.active'))
          document
            .querySelectorAll('#owl-dots .carousel-dot')[0]
            .classList.add('active');
        dots = document.querySelectorAll('#owl-dots .carousel-dot');
        for (let i = 0; i < dots.length; i++) {
          dots[i].addEventListener('click', (e) => {
            carousel.goTo(e.currentTarget.index());
          });
        }
      }
    }

    return () => {
      if (hasChildren() && isQuickViewCarousel()) {
        let dots;

        if (document.querySelector('#owl-dots .carousel-dot')) {
          if (
            !document.querySelector('#owl-dots .carousel-dot.active')
          )
            document
              .querySelectorAll('#owl-dots .carousel-dot')[0]
              .classList.add('active');
          dots = document.querySelectorAll('#owl-dots .carousel-dot');
          for (let i = 0; i < dots.length; i++) {
            dots[i].addEventListener('click', (e) => {
              carousel.goTo(e.currentTarget.index());
            });
          }
        }
      }
    };
  }, [children]);

  const events = {
    onTranslate: function (e) {
      if (!e.target) return;
      if (e.target.closest('.owl-quickview')) {
        document
          .querySelector('#owl-dots')
          .querySelector('.active')
          .classList.remove('active');
        document
          .querySelector('#owl-dots')
          .children[e.page.index].classList.add('active');
      }
    },
  };

  let slider_default_options = {
    items: 1,
    loop: false,
    margin: 0,
    responsiveClass: 'true',
    nav: true,
    navText: [
      '<i class="icon-angle-left">',
      '<i class="icon-angle-right">',
    ],
    dots: true,
    smartSpeed: 400,
    autoplay: false,
    // autoplayTimeout: 5000,
    // responsive: {
    //   320: {
    //     nav: false,
    //   },
    //   375: {
    //     nav: false,
    //   },
    //   767: {
    //     nav: true,
    //   },
    // },
  };

  let res = Object.assign(
    {},
    slider_default_options,
    carouselOptions,
  );

  return children !== undefined ? (
    children.length > 0 ||
    (children && children.length === undefined) ? (
      <OwlCarousel
        ref={carouselRef}
        className={`owl-carousel ${adClass}`}
        {...res}
        events={events}
      >
        {children}
      </OwlCarousel>
    ) : (
      ''
    )
  ) : (
    ''
  );
};

Carousel.propTypes = {
  adClass: PropTypes.string,
  carouselOptions: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Carousel;
