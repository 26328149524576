import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';
import {
  APISortDirection,
  APIStringifiedBoolean,
  createQueryString,
} from 'lib/utils';
import {
  LoyaltyPointAPISortBy,
  YbRepsListResponse,
} from './interface';

export const getUserLoyaltyPointsUrl = (
  userId: string,
  params?: GetUserLoyaltyPointsOption,
): string => {
  const formattedQueryString = createQueryString({
    per_page: params?.perPage,
    sort_dir: params?.sortDir,
    sort_field: params?.sortField,
    is_usable: params?.isUsable,
  });

  return `/users/${userId}/yb_reps?${formattedQueryString}`;
};

export const getUserLoyaltyPoints = async (
  userId: string,
  params?: GetUserLoyaltyPointsOption,
) => {
  const response = await httpClient.get<GetUserLoyaltyPointsResponse>(
    getUserLoyaltyPointsUrl(userId, params),
  );

  return response.data;
};

export interface GetUserLoyaltyPointsOption {
  perPage?: number;
  page?: number;
  sortDir?: APISortDirection;
  sortField?: LoyaltyPointAPISortBy;
  isUsable?: APIStringifiedBoolean;
}

export type GetUserLoyaltyPointsResponse = YbRepsListResponse;

export const getUserLoyaltyPointsLogs = async (userId) => {
  try {
    const response = await httpClient.get(
      `/users/${userId}/yb_reps/logs`,
    );

    return getResponseData(response).data;
  } catch (err) {
    return getErrorMessage(err);
  }
};
