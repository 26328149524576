import { EqualColsGrid } from 'components/common';
import {
  LoyaltyPointSummary,
  OutstandingOrder,
  ReferralCodeSummary,
} from './userSumarries';
import { m } from 'framer-motion';

const ICON_SIZE = 36;
const iconColor = '#A63426';

export const UserSummaries = () => {
  return (
    <EqualColsGrid cols={3}>
      <m.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25, type: 'spring', duration: 2 }}
      >
        <OutstandingOrder
          iconSize={ICON_SIZE}
          iconColor={iconColor}
          className="mb-4"
        />
      </m.div>
      <m.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, type: 'spring', duration: 2 }}
      >
        <LoyaltyPointSummary
          iconSize={ICON_SIZE}
          iconColor={iconColor}
          className="mb-4"
        />
      </m.div>
      <m.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.75, type: 'spring', duration: 2 }}
      >
        <ReferralCodeSummary
          iconSize={ICON_SIZE}
          iconColor={iconColor}
        />
      </m.div>
    </EqualColsGrid>
  );
};
