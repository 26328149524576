import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link, { LinkProps } from '../link';
import stylesheet from './style.module.scss';

type Props = {
  [Property in keyof LinkProps]?: LinkProps[Property];
};

const BannerLink = ({
  to,
  children,
  className,
  ...others
}: Props) => {
  return (
    <Link
      to={to ?? '#'}
      className={classNames(
        className,
        stylesheet['banner-link'],
        stylesheet['btn'],
      )}
      {...others}
    >
      <>{children}</>
    </Link>
  );
};

BannerLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

BannerLink.defaultProps = {
  to: '#',
};

export default BannerLink;
