import { routePaths } from 'appConfig';
import { Icon, IconBox, IconIconSet, Link } from 'components/common';
import datetime from 'lib/datetime';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes';
import {
  OrderAPIDeliveryMethod,
  useUserOutstandingOrder,
} from 'lib/order';
import { useStoreConfig } from 'lib/store';
import { useSession } from 'next-auth/client';
import { useEffect, useState } from 'react';
import { CSSProperties } from 'react';

interface Props {
  iconSize?: number;
  iconColor?: CSSProperties['color'];
  className?: string;
}

export const OutstandingOrder = ({
  iconSize = 36,
  iconColor = '#000',
  className,
}: Props) => {
  const [session] = useSession();

  const userId = session?.user?.userId;
  const { order } = useUserOutstandingOrder(userId!);
  const { storeConfig } = useStoreConfig();

  const noOrderIcon = 'no-order';
  const outstandingOrderIcon = 'upcoming-order';

  const noOrderTitle = <>Get Started Today!</>;
  const noOrderSubtitle = (
    <>
      You have no
      <br />
      upcoming deliveries.
    </>
  );

  const noOrderLink = routePaths.CATEGORIES;
  const noOrderLinkLabel = 'Place an Order';

  const [icon, setIcon] = useState(noOrderIcon);
  const [title, setTitle] = useState(noOrderTitle);
  const [subtitle, setSubTitle] = useState(noOrderSubtitle);
  const [link, setLink] = useState(noOrderLink);
  const [linkLabel, setLinkLabel] = useState(noOrderLinkLabel);

  const formatDeliverySlot = async (): Promise<JSX.Element> => {
    const deliverySlot = datetime(order?.delivery_datetime).tz(
      storeConfig?.timezone,
    );
    const deliverySlotEnd = deliverySlot
      .clone()
      .add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hour');
    const deliveryDate = deliverySlot.format('D MMM, ddd,');
    const startDeliverySlot = deliverySlot.format('ha');
    const endDeliverySlot = deliverySlotEnd.format('ha');

    return (
      <>
        {deliveryDate} {`${startDeliverySlot}-${endDeliverySlot}`}
      </>
    );
  };

  const formatOrderInfo = async () =>
    order?.delivery_method ===
    OrderAPIDeliveryMethod.CLICK_AND_COLLECT ? (
      <>
        <p className="m-0 small">Your upcoming collection</p>
        <p className="small">for Order #{order?.order_number}.</p>
      </>
    ) : (
      <>
        <p className="m-0 small">Your upcoming delivery</p>
        <p className="small">for Order #{order?.order_number}.</p>
      </>
    );

  const getOrderLink = async (): Promise<string> =>
    `/orders/${order?.id}`;

  useEffect(() => {
    const updateOrderInformation = async () => {
      if (order === null) {
        setIcon(noOrderIcon);
        setTitle(noOrderTitle);
        setSubTitle(noOrderSubtitle);
        setLink(noOrderLink);
        setLinkLabel(noOrderLinkLabel);
        return;
      }

      setIcon(outstandingOrderIcon);
      setTitle(await formatDeliverySlot());
      setSubTitle(await formatOrderInfo());
      setLink(await getOrderLink());
      setLinkLabel('View Order');
    };

    updateOrderInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, order?.delivery_datetime]);

  return (
    <IconBox
      boxStyle={'icon-box-left'}
      className={`ml-4 ${className}`}
      icon={
        <Icon
          iconSet={IconIconSet.CUSTOM}
          name={icon}
          size={iconSize}
          style={{
            color: iconColor,
            width: '80px',
            position: 'absolute',
            top: 0,
          }}
        />
      }
      title={title}
      text={
        <div className="mt-1">
          {subtitle}
          <Link
            className="mb-0 mt-2 d-block text-primary-lighter"
            to={link}
          >
            <>
              {linkLabel} <i className="icon-long-arrow-right"></i>
            </>
          </Link>
        </div>
      }
    />
  );
};
